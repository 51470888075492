import styled from "styled-components";
import media from "styled-media-query";

export const PostHeader = styled.header`
  color: var(--postColor);
  max-width: 70rem;
  ${media.lessThan("large")`
    max-width: 100%;
  `}
`;
export const RecentsTitle = styled.div`
  padding: 1rem var(--default-padding-desktop);
  ${media.lessThan("large")`
      padding: 1rem var(--default-padding-mobile);
     
    `}
`;
export const PostContent = styled.section`
  padding: 2rem var(--default-padding-desktop);
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 3fr 1fr;
  ${media.lessThan("large")`
    padding: 1rem var(--default-padding-mobile);
    grid-template-columns: 1fr;
  `}
  .tags {
    font-size: var(--label-size);
    font-weight: 100;
    display: flex;
    width: 100%;
    color: var(--esx-black);
    gap: 0.5rem;
    opacity: 0.8;
    flex-wrap: wrap;
    p {
      &::before {
        content: "#";
      }
    }
  }
  .category {
    font-size: 1.1rem;
    font-weight: 100;
    padding: 0.5rem 1rem;
    color: #fafafa;
    border-radius: 20px;
  }
  .main-content {
    width: 100%;
  }
  .sidebar-content {
    ul.categories {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      padding-bottom: 2rem;
      li {
        &:hover {
          color: #fafafa;
          background: var(--esx-red);
        }
        border: 1px solid var(--esx-red);
        padding: 0.1rem 1rem;
        border-radius: 20px;
      }
    }
    .posts-list {
      margin-bottom: 1rem;
      list-style: none;
      margin-left: 0;
      .posts-list-title,
      .posts-list-desc {
        font-size: 1.3rem;
        display: -webkit-box;
        color: var(--esx-black);
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .posts-list-title {
        color: var(--esx-red);

        text-decoration: underline;
      }
      a:hover {
        text-decoration: underline;
      }
      .posts-list-desc {
        font-size: 0.9rem;
        -webkit-line-clamp: 3;
      }
    }
  }
`;

export const PostTitle = styled.h1`
  font-size: var(--large-title-size);
  font-weight: 700;
  margin: 1rem auto;
  ${media.lessThan("large")`
    font-size: 2.8rem;    line-height: 1.1;

  `}
  ${media.lessThan("medium")`
      font-size: var(--medium-title-size);
    `}
`;

export const PostDescription = styled.h2`
  font-size: 2rem;
  font-weight: 200;

  ${media.lessThan("large")`
    font-size: 1.6rem;
    line-height: 1.3;

  `}
`;

export const PostDate = styled.p`
  font-size: 1.1rem;
  font-weight: 100;
`;

export const MainContent = styled.section`
  max-width: 70rem;
  padding: 1rem 0;
  ${media.lessThan("medium")`
    max-width: 100%;
  `}
  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
    ${media.lessThan("large")`
      word-break: break-word;
    `}
  }
  p {
    margin: 0 auto 1.6rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2.4rem auto 1rem;
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  img {
    display: block;
    max-width: 100%;
  }
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;
    ${media.lessThan("large")`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: var(--postColor);
    border-left: 0.3rem solid var(--esx-red);
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid var(--borders);
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
    letter-spacing: 0.069rem;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.8rem;
    ${media.lessThan("large")`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan("large")`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan("large")`
      font-size: 1.125rem;
    `}
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    &.lazyload {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;
    ${media.lessThan("large")`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    border-bottom: 1px dashed var(--highlight);
    color: var(--highlight);
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: var(--postColor);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 2rem 0;
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--esx-red);
    color: white;
  }
`;
