import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";

import * as S from "../components/Post/styled";

const BlogPost = ({ data, pageContext }) => {
  return (
    <Layout>
      <h1>Blog post/</h1>
    </Layout>
  );
};

  export default BlogPost;